import { SET_ACTIVITY_LIST } from "./Types";

const init_data = []

const ActivityListReducer = function (state = init_data, { type, payload }) {
    switch (type) {
        case SET_ACTIVITY_LIST:
            return [...payload];
        default: return state;
    }
}

export default ActivityListReducer;