import { ADD_ACTIVITY_CONTEXT, ADD_ACTIVITY_TYPE, CLEAR_INTERACTED, SET_ACCESSIBILITY, SET_ACTIVITY_TYPE, SET_AGE, SET_ASSURANCE, SET_AVAILABILITY, SET_DISTANCE, SET_FACILITIES, SET_GENDER, SET_INTENSITY, SET_INTERACTED, SET_LATITUDE, SET_LONGITUDE, SET_PARTICIPATION, SET_POSTCODE, SET_SUPPORT } from "./Types";


export const getClearInteractedAction = () => ({
    type: CLEAR_INTERACTED,
})

export const getSetAgeInteractedAction = (payload) => ({
    type: SET_AGE,
    payload
})
export const getSetGenderInteractedAction = (payload) => ({
    type: SET_GENDER,
    payload
})
export const getSetDistanceInteractedAction = (payload) => ({
    type: SET_DISTANCE,
    payload
})
export const getSetPostcodeInteractedAction = (payload) => ({
    type: SET_POSTCODE,
    payload
})

export const getSetAccessibilityInteractedAction = (payload) => ({
    type: SET_ACCESSIBILITY,
    payload
})
export const getSetAvailabilityInteractedAction = (payload) => ({
    type: SET_AVAILABILITY,
    payload
})

export const getSetActivityContextInteractedAction = (payload) => ({
    type: ADD_ACTIVITY_CONTEXT,
    payload
})

export const getAddActivityTypeInteractedAction = (payload) => ({
    type: ADD_ACTIVITY_TYPE,
    payload,
})

export const getSetActivityTypeInteractedAction = (payload) => ({
    type: SET_ACTIVITY_TYPE,
    payload,
})

export const getSetInteractedAction = (payload) => ({
    type: SET_INTERACTED,
    payload
})

export const getSetLatitudeAction = (payload) => ({
    type: SET_LATITUDE,
    payload
})

export const getSetLongitudeAction = (payload) => ({
    type: SET_LONGITUDE,
    payload
})

export const getSetIntensityAction = (payload) => ({
    type: SET_INTENSITY,
    payload
})

export const getSetFacilitiesAction = (payload) => ({
    type: SET_FACILITIES,
    payload,
})

export const getSetParticipationAction = (payload) => ({
    type: SET_PARTICIPATION,
    payload
})

export const getSetSupportAction = (payload) => ({
    type: SET_SUPPORT,
    payload,
})

export const getSetAssuranceAction = (payload) => ({
    type: SET_ASSURANCE,
    payload,
})
