import { createUser } from "../../../Functions/Admin/functions";
import { getSetToggleConsoleLogAction, getSetToggleExperimentalAction } from "../../../Store/reducers/Config/Actions";
import store from "../../../Store/store";

export const SettingsItems = [
    {
        id: 'path',
        title: 'Current Path',
        subtitle: store.getState().Config.path,
        type: 'form',
        menu: 'tools'
    },
    {
        id: 'showConsoleLogs',
        title: 'Console Log',
        subtitle: 'Toggle console log',
        type: 'switch',
        menu: 'settings',
        onChange: (e) => {
            store.dispatch(getSetToggleConsoleLogAction())
        }
    },
    {
        id: 'showExperimental',
        title: 'Experimental',
        subtitle: 'Toggle experimental features',
        type: 'switch',
        menu: 'settings',
        onChange: () => {
            store.dispatch(getSetToggleExperimentalAction());
        }
    }, {
        id: 'activityToken',
        title: 'Activity Token',
        subtitle: 'Get activity token',
        type: 'two-text',
        menu: 'tools',
        onSubmit: (id) => {
            console.log(id);
            return btoa(id);
        }
    },
    {
        id: 'registerNewUser',
        title: 'New User',
        subtitle: 'Add new user into system',
        type: 'registration',
        menu: 'tools',
        onChange: (e) => {
            const { value, id } = e.target;
            let elType = id.split('-')[0];
            let pattern;
            switch (elType) {
                case 'email':
                    let email = value.trim().toLowerCase();
                    pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    let emailError = !pattern.test(String(email));
                    return {
                        email,
                        emailError,
                        error: false
                    }
                case 'password':
                    let password = value.trim();
                    let cryptedPass = btoa(password);
                    pattern = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{5,})/
                    let passwordError = !pattern.test(String(password));
                    return {
                        password: passwordError ? password : cryptedPass,
                        passwordError,
                        error: false,
                    }
                default: return false;
            }
        },
        onSubmit: async (registerState) => {
            const { email, password } = registerState;
            return await createUser(email, password)
                .then(res => {
                    return res
                })
        }
    }
]