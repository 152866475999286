import { SET_ACTIVITY_INDIVIDUAL_TEST, SET_ACTIVITY_TEAM_LIST } from "./Types";

const init_data = {}

const ActivityTypes = function (state = init_data, { type, payload }) {
    switch (type) {
        case SET_ACTIVITY_INDIVIDUAL_TEST:
            return {
                ...state,
                individual: [...payload]
            };
        case SET_ACTIVITY_TEAM_LIST:
            return {
                ...state,
                team: [...payload]
            };
        default: return state;
    }
}

export default ActivityTypes;