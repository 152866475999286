export const Colors = {
    grey1: '#374355',
    grey2: '#3D4D65',
    grey3: '#53657D',
    white1: '#FFFFFF',
    white2: '#EBEFF3',
    white3: '#C9D3DF',
    accentRed: '#D1335B',
    accentDarkRed: '#AB294A',
    accentDarkerRed: '#5D1326',
    accentOrange: '#FF9947',
    accentDarkOrange: '#CC5B01',
    accentDarkerOrange: '#5A2800',
    accentBlue: '#0081D5',
    accentDarkBlue: '#0061A1',
    accentDarkerBlue: '#003252',
    accentGreen: '#03BD5B',
    accentDarkGreen: '#007C3B',
    accentDarkerGreen: '#003B1C',
    accentPurple: '#A939B9',
    accentDarkPurple: '#7B2887',
    accentDarkerPurple: '#4A1652',
    accentTeal: '#0FA3A3',
    accentDarkTeal: '#0A7676',
    accentDarkerTeal: '#053C3C',
    tagDefaultColor: '#ff9090',
    favYellow: '#F4D03F'
}

export const InteractedElements = {
    postcode: 'postCode',
    latitude: 'latitude',
    longitude: 'longitude',
    distance: 'distance',
    age: 'age',
    gender: 'gender',
    accessibility: 'accessibility',
    availability: 'availability',
    activityContext: 'activityContext',
    activityTypes: 'activityTypes',
    intensity: 'intensity',
    facilities: 'facilities',
    participation: 'participation',
    support: 'support',
    assurance: 'assurance',
}

//Using this array, we'll hide tags in Search Results
export const HIDDEN_TAGS = [InteractedElements.latitude, InteractedElements.longitude, 'id'];

export const colorsMapping = [
    {
        value: InteractedElements.postcode,
        color: Colors.accentBlue
    },
    {
        value: InteractedElements.distance,
        color: Colors.accentBlue
    },
    {
        value: InteractedElements.age,
        color: Colors.accentBlue
    },
    {
        value: InteractedElements.gender,
        color: Colors.accentBlue
    },
    {
        value: InteractedElements.accessibility,
        color: Colors.accentBlue
    },
    {
        value: InteractedElements.activityTypes,
        color: Colors.accentGreen
    },
    {
        value: InteractedElements.availability,
        color: Colors.accentTeal
    },
    {
        value: InteractedElements.intensity,
        color: Colors.accentPurple,
    },
    {
        value: InteractedElements.facilities,
        color: Colors.accentPurple,
    },
    {
        value: InteractedElements.participation,
        color: Colors.accentPurple,
    },
    {
        value: InteractedElements.support,
        color: Colors.accentPurple,
    },
    {
        value: InteractedElements.assurance,
        color: Colors.accentPurple,
    },
    {
        value: InteractedElements.latitude,
        color: Colors.favYellow
    },
    {
        value: InteractedElements.longitude,
        color: Colors.favYellow
    },
]