import {
    combineReducers
} from 'redux';
import ActivityTypes from './ActivitiesTypes/ActivityTypes';
import ActivityList from './ActivityList/ActivityList';
import ActivitySelected from './ActivitySelected/ActivitySelected';
import Areas from './Areas/Areas';
import Config from './Config/Config';
import FavActivities from './FavActivities/FavActivities';
import FavSearches from './FavSearches/FavSearches';
import InstanceOpened from './InstanceOpened/InstanceOpened';
import Interacted from './Interacted/Interacted';
import Languages from './Languages/Languages';
import SearchHistory from './SearchHistory/SearchHistory';
import SearchResults from './SearchResults/SearchResults';
import SelectedArea from './SelectedArea/SelectedArea';
import User from './User/User';

export default combineReducers({
    Config,
    Interacted,
    SelectedArea,
    Languages,
    Areas,
    FavActivities,
    SearchResults,
    ActivitySelected,
    ActivityList,
    User,
    InstanceOpened,
    SearchHistory,
    ActivityTypes,
    FavSearches,
})