import { GlobalSettings } from "../../Settings";

export const getUserAuthData = (email, password) => ({
    apiKey: GlobalSettings.apiKey,
    instanceKey: GlobalSettings.instanceKey,
    email,
    password
})

export const getUserData = (id) => ({
    apiKey: GlobalSettings.apiKey,
    id
})

export const getNewAreaData = (area, userid, instanceid) => ({
    apiKey: GlobalSettings.apiKey,
    area,
    userid,
    instanceid
})


export const toggleAreaData = (areaid) => ({
    apiKey: GlobalSettings.apiKey,
    areaid
})

export const deleteAreaData = (areaid) => ({
    apiKey: GlobalSettings.apiKey,
    areaid
})