import { ADD_SEARCH_HISTORY, CLEAR_SEARCH_HISTORY, REMOVE_SEARCH_HISTORY } from "./Types";

const init_data = []

const SearchHistory = function (state = init_data, { type, payload }) {
    switch (type) {
        case ADD_SEARCH_HISTORY:
            return [...state, payload];
        case CLEAR_SEARCH_HISTORY:
            return [...init_data];
        case REMOVE_SEARCH_HISTORY:
            console.log(payload);
            return [...state.filter(search => search.id !== payload.interactedData.id)];
        default: return state;
    }
}

export default SearchHistory;