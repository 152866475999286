import { ADD_SEARCH_TO_FAV, CLEAR_FAV_SEARCHES, REMOVE_SEARCH_FROM_FAV } from "./Types";

const init_data = []

const FavSearches = function (state = init_data, { type, payload }) {
    switch (type) {
        case ADD_SEARCH_TO_FAV:
            return [...state, payload];
        case REMOVE_SEARCH_FROM_FAV:
            return [...state.filter(fav => fav.interactedData.id !== payload.interactedData.id)]
        case CLEAR_FAV_SEARCHES:
            return [...init_data]
        default: return state;
    }
}

export default FavSearches;