import { SET_ACTIVITY_SELECTED } from "./Types";

const init_data = {}

const ActivitySelected = function (state = init_data, { type, payload }) {
    switch (type) {
        case SET_ACTIVITY_SELECTED:
            return { ...payload };
        default: return state;
    }
}

export default ActivitySelected;