import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../Assets/logo/DC_logo_with_title.png';
import { Colors } from '../../../Globals';
import { Paths } from '../../../Routes';
import { GlobalSettings } from '../../../Settings';

const Footer = ({
    tabWidth
}) => {
    return (
        <footer className="mt-auto px-4 py-2 pb-5 d-flex justify-content-center align-items-center flex-column" style={{
            backgroundColor: Colors.white2,
        }}>
            <div className={`${tabWidth < GlobalSettings.pcWidthSize ? 'justify-content-center' : 'justify-content-start'} d-flex align-items-center w-100`}>
                <img
                    style={{
                        width: '100%',
                        maxWidth: 500,
                        height: 'auto',
                    }}
                    src={logo}
                />
            </div>

            {/* Links */}
            <div className="noPrint w-100">
                <div className={`d-flex ${tabWidth < GlobalSettings.pcWidthSize ? 'justify-content-center' : 'justify-content-start'} align-items-center w-100`}>
                    <ul className="w-100 p-0" style={{
                        listStyle: 'none'
                    }}>
                        <li className={`w-100 d-flex ${tabWidth < GlobalSettings.pcWidthSize ? 'justify-content-center' : 'justify-content-start'}`}>
                            <Link to={Paths.aboutPage.path} className={`btn btn-link text-decoration-none text-secondary`}>About</Link>
                        </li>
                        <li className={`w-100 d-flex ${tabWidth < GlobalSettings.pcWidthSize ? 'justify-content-center' : 'justify-content-start'}`}>
                            <Link to={Paths.contactUsPage.path} className={`btn btn-link text-decoration-none text-secondary`}>Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer