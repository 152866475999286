import { SET_AREAS } from "./Types";

const init_data = []

const AreasReducer = function (state = init_data, { type, payload }) {
    switch (type) {
        case SET_AREAS:
            return [...payload];
        default: return state;
    }
}

export default AreasReducer;