import { GlobalSettings } from "../../Settings";

export const getApiInfoData = () => ({
    apiKey: GlobalSettings.apiKey,
    apiid: GlobalSettings.apiID,
})

export const getLanguagesData = () => ({
    apiKey: GlobalSettings.apiKey,
})

export const getCircumstancesData = () => ({
    apiKey: GlobalSettings.apiKey,
    apiid: GlobalSettings.apiID,
})

export const getPostcodeData = (postcode) => ({
    apiKey: GlobalSettings.geoHelperKey,
    postcode
})
