import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { equals } from '../../../functions';
import store from '../../../Store/store';

const ItemModule = ({
    id,
    onChange,
    title,
    subtitle,
    type,
    onSubmit
}) => {

    const history = useHistory();

    const [state, setState] = useState('');
    const [tempURL, setTempURL] = useState('');
    const [activityToken, setActivityToken] = useState({
        original: '',
        parsed: ''
    });

    const [registerState, setRegisterState] = useState({
        error: false,
        loading: false,
        email: '',
        emailError: true,
        password: '',
        passwordError: true,
        validated: false,
        message: '',
    })

    useEffect(() => {
        let unsubscribe = store.subscribe(() => updateFromStore());

        const updateFromStore = () => {
            let storeData = store.getState().Config[id];
            if (!equals(storeData, state)) {
                setState(storeData);
                if (type === 'form') {
                    setTempURL(storeData);
                }
            }
        }
        updateFromStore();

        return () => {
            unsubscribe();
        }
    }, []);

    const getType = () => {
        switch (type) {
            case 'switch':
                return (
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <div className="d-flex flex-column justify-content-center align-items-start">
                            < Card.Title >{title}</Card.Title>
                            <Card.Subtitle>{subtitle}</Card.Subtitle>
                        </div>
                        <Form.Switch
                            type
                            onClick={onChange}
                            checked={state}
                            id={`disabled-custom-switch-${id}`} />
                    </div>
                )
            case 'form':
                return (
                    <div className="d-flex flex-column justify-content-center align-items-start">
                        < Card.Title >{title}</Card.Title>
                        <div className="d-flex justify-content-center align-items-center w-100">
                            <Form className="w-100" onSubmit={(e) => { e.preventDefault(); history.push(tempURL) }}>
                                <Row >
                                    <Col md="10" className="mt-2">
                                        <Form.Control
                                            onChange={(e) => setTempURL(e.target.value)}
                                            type="text"
                                            placeholder={tempURL}
                                            id={`change-path-url-${id}`}
                                            className="d-flex justify-content-center align-items-center" />
                                    </Col>
                                    <Col md="2" className="mt-2 d-flex justify-content-end align-items-center">
                                        <Link type="submit" className="btn btn-primary text-decoration-none" to={tempURL} variant="primary">Go</Link>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                )
            case 'two-text':
                return (
                    <div className="d-flex flex-column justify-content-center align-items-start">
                        < Card.Title >{title}</Card.Title>
                        <Card.Subtitle>{subtitle}</Card.Subtitle>
                        <div className="d-flex justify-content-center align-items-center w-100">
                            <Form className="w-100" onSubmit={(e) => { e.preventDefault(); setActivityToken({ ...activityToken, parsed: onSubmit(activityToken.original) }) }}>
                                <Row className="mb-2">
                                    <Col md="10" className="mt-2">
                                        <Form.Control
                                            onChange={(e) => setActivityToken({ ...activityToken, original: e.target.value })}
                                            type="text"
                                            placeholder={'Type some activity id'}
                                            id={`parse-activity-id-to-base64-${id}`}
                                            className="d-flex justify-content-center align-items-center" />
                                    </Col>
                                    <Col md="2" className="mt-2 d-flex justify-content-end align-items-center">
                                        <Button type="submit" className="text-decoration-none" variant="primary">Parse</Button>
                                    </Col>
                                </Row>
                                {activityToken.parsed &&
                                    <div className="border p-2 mt-2 w-100 rounded d-flex justify-content-center align-items-center">
                                        <code className="text-wrap d-flex justify-content-center" style={{
                                            wordBreak: 'break-word'
                                        }}>
                                            {activityToken.parsed}
                                        </code>
                                    </div>
                                }
                            </Form>
                        </div>
                    </div>
                )
            case 'registration':
                return (
                    <div className="d-flex flex-column justify-content-center align-items-start">
                        < Card.Title >{title}</Card.Title>
                        <Card.Subtitle>{subtitle}</Card.Subtitle>
                        <div className="d-flex justify-content-center align-items-center w-100">
                            <Form validated={registerState.validated} className="w-100" onSubmit={(e) => {
                                e.preventDefault();
                                setRegisterState({
                                    ...registerState,
                                    loading: true,
                                })
                                if (!registerState.emailError && !registerState.passwordError) {
                                    onSubmit(registerState)
                                        .then(res => {
                                            console.log(res);
                                            setRegisterState({
                                                ...registerState,
                                                loading: false,
                                                validated: true,
                                                error: !res.status,
                                                message: res.message
                                            })
                                        })
                                } else {
                                    setRegisterState({
                                        ...registerState,
                                        error: true,
                                        loading: false,
                                    })
                                }
                            }}>
                                <Form.Group>
                                    <Form.Control
                                        required
                                        onChange={(e) => {
                                            setRegisterState({
                                                ...registerState,
                                                ...onChange(e)
                                            })
                                        }}
                                        isInvalid={registerState.email && registerState.emailError}
                                        type="text"
                                        placeholder={'Type email'}
                                        id={`email-${id}`}
                                        className="my-1 d-flex justify-content-center align-items-center" />
                                    <Form.Control.Feedback type={registerState.emailError ? 'invalid' : 'valid'}>{registerState.emailError ? 'Invalid' : 'Valid'} email</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control
                                        required
                                        isInvalid={registerState.password && registerState.passwordError}
                                        onChange={(e) => {
                                            setRegisterState({
                                                ...registerState,
                                                ...onChange(e)
                                            })
                                        }}
                                        type="text"
                                        placeholder={'Type password'}
                                        id={`password-${id}`}
                                        className="my-1 d-flex justify-content-center align-items-center" />
                                    <Form.Control.Feedback type={registerState.passwordError ? 'invalid' : 'valid'}>{registerState.passwordError ? 'Invalid' : 'Valid'} password</Form.Control.Feedback>
                                </Form.Group>
                                <div className="d-flex w-100 justify-content-between align-items-center">
                                    <div>
                                        {registerState.validated &&
                                            <p className={`mb-0 ${registerState.error ? 'text-danger' : ''}`}>{registerState.message}</p>
                                        }
                                    </div>
                                    <Button type="submit" className="text-decoration-none" variant="primary">
                                        {registerState.loading ?
                                            <div className="d-flex justify-content-center align-items-center">
                                                <Spinner animation="border" size="sm" />
                                                <p className="mb-0 ml-2">Adding user...</p>
                                            </div>
                                            :
                                            <p className="mb-0">Register</p>
                                        }
                                    </Button>
                                </div>
                                {activityToken.parsed &&
                                    <div className="border p-2 mt-2 w-100 rounded d-flex justify-content-center align-items-center">
                                        <code className="text-wrap d-flex justify-content-center" style={{
                                            wordBreak: 'break-word'
                                        }}>
                                            {activityToken.parsed}
                                        </code>
                                    </div>
                                }
                            </Form>
                        </div>
                    </div>
                )
        }
    }

    return (
        <Card className="p-0 my-2">
            <Card.Body >
                {getType()}
            </Card.Body>
        </Card >
    )
}

export default ItemModule;