import { SET_API, SET_API_QUERY, SET_HOME, SET_PATH, TOGGLE_CONSOLE_LOG, TOGGLE_EXPERIMENTAL } from "./Types";

export const getSetPathAction = (payload) => ({
    type: SET_PATH,
    payload,
})

export const getSetApiQueryAction = (payload) => ({
    type: SET_API_QUERY,
    payload
})

export const getSetApiURLAction = (payload) => ({
    type: SET_API,
    payload,
})

export const getSetToggleConsoleLogAction = () => ({
    type: TOGGLE_CONSOLE_LOG,
})

export const getSetToggleExperimentalAction = () => ({
    type: TOGGLE_EXPERIMENTAL,
})

export const getSetHomeAction = () => ({
    type: SET_HOME
})