import { SET_AREAS, SET_SELECTED_AREA } from "./Types";

const init_data = {}

const SelectedArea = function (state = init_data, { type, payload }) {
    switch (type) {
        case SET_SELECTED_AREA:
            return { ...payload };
        default: return state;
    }
}

export default SelectedArea;