export const CLEAR_INTERACTED = 'CLEAR_INTERACTED';

export const SET_AGE = 'SET_AGE';
export const SET_GENDER = 'SET_GENDER';
export const SET_DISTANCE = 'SET_DISTANCE';
export const SET_POSTCODE = 'SET_POSTCODE';
export const SET_ACCESSIBILITY = 'SET_ACCESSIBILITY';
export const SET_AVAILABILITY = 'SET_AVAILABILITY';
export const ADD_ACTIVITY_CONTEXT = "ADD_ACTIVITY_CONTEXT";
export const ADD_ACTIVITY_TYPE = 'ADD_ACTIVITY_TYPE';
export const SET_ACTIVITY_TYPE = 'SET_ACTIVITY_TYPE';

export const SET_INTERACTED = "SET_INTERACTED";

export const SET_LATITUDE = 'SET_LATITUDE';
export const SET_LONGITUDE = 'SET_LONGITUDE';

export const SET_INTENSITY = "SET_INTENSITY";
export const SET_FACILITIES = 'SET_FACILITIES';
export const SET_PARTICIPATION = 'SET_PARTICIPATION';
export const SET_SUPPORT = 'SET_SUPPORT';
export const SET_ASSURANCE = 'SET_ASSURANCE';