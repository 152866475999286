import { REMOVE_USER, SET_USER } from "./Types";

export const getSetUserAction = (payload) => ({
    type: SET_USER,
    payload,
})

export const getRemoveUserAction = (payload) => ({
    type: REMOVE_USER,
    payload
})
