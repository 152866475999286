import { consoleLogEnabled } from "../../../functions";
import { SET_LANGUAGES } from "./Types";

const init_data = []

const LanguagesReducer = function (state = init_data, { type, payload }) {
    switch (type) {
        case SET_LANGUAGES:
            consoleLogEnabled() && console.log(payload);
            return [...payload];
        default: return state;
    }
}

export default LanguagesReducer;