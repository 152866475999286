import React, { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import tsfLogo from '../../../Assets/logo/DC_logo_with_title.png';
import { consoleLogEnabled, equals } from "../../../functions";
import { checkPath } from "../../../Functions/Navbar/checkPage";
import { getAllAreas } from "../../../Functions/Navbar/functions";
import { Colors } from "../../../Globals";
import { Paths } from "../../../Routes";
import { GlobalSettings } from "../../../Settings";
import { getSetSelectedAreaAction } from "../../../Store/reducers/SelectedArea/Actions";
import store from "../../../Store/store";

const ContextLogo = ({ isMobile }) => (
    <img
        style={{
            width: '100%',
            maxWidth: isMobile ? 150 : 250,
            height: 'auto',
        }}
        src={tsfLogo} />
)

const Header = ({ tabWidth }) => {

    const adminAreaOption = { value: -1, label: 'Manage Areas', original: { selected: 0 } };

    const history = useHistory();

    const [isAreaSelectorVisible, setShowAreaSelector] = useState(true);

    const [language, setLanguage] = useState({});
    const [languages, setLanguages] = useState([]);

    const [areas, setAreas] = useState([adminAreaOption]);
    const [areaSelected, setAreaSelected] = useState(null)
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        getAllAreasFunction();
    }, [])

    //Store Controller
    useEffect(() => {
        const unsubscribe = store.subscribe(() => updateFromStore())

        const updateFromStore = () => {
            //Areas controller
            const { Areas: areasStore } = store.getState();
            if (!equals(areasStore, areas)) {
                sanitizeAreas(areasStore);
            }

            //All languages
            let languageStore = store.getState().Languages;
            if (!equals(languageStore, languages)) {
                setLanguages(languageStore);
                //Language by default
                let cookies = document.cookie;
                if (cookies) {
                    let cookieJSON = JSON.parse(cookies);
                    setLanguage(cookieJSON.languageSelected);
                } else {
                    let languageByDefault = languageStore.filter(lan => +lan.isdefault === 1)[0];
                    setLanguage({ value: languageByDefault.id, label: languageByDefault.localisedname, original: languageByDefault });
                }
            }

            //Location controller
            let pathStore = store.getState().Config.path;
            let { showAreaSelector } = checkPath(pathStore)
            setShowAreaSelector(showAreaSelector);

        }
        return () => {
            unsubscribe();
        }
    }, [language, areaSelected])

    const sanitizeAreas = (areas) => {
        const finalAreas = [];
        areas.map(area => {
            let tempArea = {
                value: area.id,
                label: area.name,
                original: area
            };
            finalAreas.push(tempArea)
        })

        //Select area by default if is the first render
        if (areaSelected === null) {
            let areaSelectedByDefault = finalAreas.filter(area => +area.original.selected === 1)[0];
            if (areaSelectedByDefault) {
                consoleLogEnabled() && console.log(areaSelectedByDefault);
                setAreaSelected(areaSelectedByDefault);
            }
        }

        finalAreas.push(adminAreaOption);
        setAreas(finalAreas);
    }

    const getAllAreasFunction = async () => {
        getAllAreas(true).then(res => {
            consoleLogEnabled() && console.log(res);
            setLoaded(true);
        });
    }

    const onSelectArea = async (e) => {
        if (e.value === -1) return history.replace(Paths.adminPath.path)
        await store.dispatch(getSetSelectedAreaAction(e.original));
        setAreaSelected(e);
    }

    return (
        <Navbar id="mainNavbar" sticky="top" collapseOnSelect expand="md" variant="light" style={{
            backgroundColor: Colors.white2
        }}>
            <Navbar.Brand className="mr-0 d-flex align-items-center">
                <Link className="text-dark text-decoration-none mx-2 mb-0 pb-0" to={Paths.homePage.path}>
                    <img
                        src={GlobalSettings.logoSettings.logoImg}
                        width={tabWidth < GlobalSettings.pcWidthSize ? GlobalSettings.logoSettings.logoWidthMobile : GlobalSettings.logoSettings.logoWidth}
                        height={tabWidth < GlobalSettings.pcWidthSize ? GlobalSettings.logoSettings.logoHeightMobile : GlobalSettings.logoSettings.logoHeight}
                        className="mx-2 d-inline-block align-top"
                        alt="the_service_finder"
                    />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle className="px-1 border-0 col-auto" aria-controls="responsive-navbar-nav">
                <div className=" d-flex align-items-end flex-column">
                    <i className="border rounded p-2 material-icons">menu</i>
                    <ContextLogo isMobile={true} />
                </div>
            </Navbar.Toggle>

            <Navbar.Collapse id="responsive-navbar-nav">
                <div className={`d-flex ${tabWidth < GlobalSettings.pcWidthSize ? 'justify-content-center' : 'justify-content-end'} w-100`}>
                    <Nav className={tabWidth < GlobalSettings.pcWidthSize ? 'w-100' : ''}>
                        {isAreaSelectorVisible &&
                            <Nav.Item
                                style={{
                                    width: '100%',
                                    maxWidth: '100%',
                                    minWidth: 200
                                }}
                                className="d-flex align-items-center flex-column justify-content-center">
                                <p className="mb-0 mt-2">Context:</p>
                                <Select
                                    isSearchable={areas.length > 10}
                                    onChange={onSelectArea}
                                    value={areaSelected}
                                    options={areas}
                                    placeholder={loaded ? 'Select Area...' : 'Loading...'}
                                    id="areaDropdown"
                                    className="w-100" />
                                {tabWidth >= GlobalSettings.pcWidthSize &&
                                    <ContextLogo isMobile={false} />
                                }
                            </Nav.Item>
                        }
                    </Nav>
                </div>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header