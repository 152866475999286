import React, { useEffect, useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { GlobalSettings } from '../../../Settings';
import ItemModule from './ItemModule';
import { SettingsItems } from './SettingsItems';


const SideSettings = () => {

    const [settingsHandleWidth, setSettingsHandleWidth] = useState(45);
    const [showSettings, setShowSettings] = useState(GlobalSettings.showSettingsMenuByDefault);
    const [settingsWidth, setSettingsWidth] = useState(500);

    //ComponentDidMount
    useEffect(() => {
        resizeController({ target: window });
        window.addEventListener('resize', resizeController)
        // let settingsWidth = window.$('#settingsHandle').width();
        // consoleLogEnabled() && console.log(settingsWidth);
        // setSettingsHandleWidth(settingsWidth);

        return () => {
            window.removeEventListener('resize', resizeController);
        }
    }, [])

    const resizeController = (e) => {
        const { innerWidth } = e.target;
        setSettingsWidth(innerWidth > 992 ? 500 : innerWidth);
    }

    return (
        <>
            {showSettings &&
                <div onClick={() => { setShowSettings(false) }} style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                    zIndex: 99999,
                }} />
            }
            <div id="sideSettings" className="shadow border bg-light" style={{
                position: 'fixed',
                zIndex: 100000,
                height: '100vh',
                transition: '0.5s',
                width: settingsWidth,
                right: showSettings ? 0 : -settingsWidth,
                top: 0, bottom: 0
            }}>
                {/* Handle Settings */}
                <div
                    onClick={() => {
                        setShowSettings(!showSettings);
                    }}
                    id="settingsHandle"
                    className="pointer shadow border bg-light"
                    style={{
                        transition: '0.3s',
                        position: 'absolute',
                        left: -settingsHandleWidth,
                        padding: 15,
                        borderRadius: 10,
                        top: 0,
                        bottom: 0,
                        height: 55,
                        margin: 'auto 0',
                        opacity: showSettings ? 0 : 1,
                    }}>
                    <span className="material-icons">settings</span>
                </div>

                {/* Settings container */}
                <div>
                    <Card className="border-0 rounded-0">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <div>
                                <Card.Title>Developer Settings</Card.Title>
                                <Card.Subtitle className="text-muted">Adjust settings in developer mode</Card.Subtitle>
                            </div>
                            <div>
                                <span className="material-icons text-danger pointer"
                                    onClick={() => {
                                        setShowSettings(false);
                                    }}>
                                    close
                                </span>
                            </div>
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Tabs defaultActiveKey="1" id="uncontrolled-tab-example" className="m-0">
                                <Tab eventKey="1" title="Settings" className="border bg-white p-2 rounded-bottom">
                                    {SettingsItems.map((item, index) => (
                                        item.menu === 'settings' &&
                                        <ItemModule {...item} key={index} />
                                    ))}
                                </Tab>
                                <Tab eventKey="2" title="Tools" className="border bg-white p-2 rounded-bottom">
                                    {SettingsItems.map((item, index) => (
                                        item.menu === 'tools' &&
                                        <ItemModule {...item} key={index} />
                                    ))}
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default SideSettings;