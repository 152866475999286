import { REMOVE_USER, SET_USER } from "./Types";

const init_data = {}

const User = function (state = init_data, { type, payload }) {
    switch (type) {
        case SET_USER:
            return { ...payload }
        case REMOVE_USER:
            return {}
        default: return state;
    }
}

export default User;