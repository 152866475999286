import { Colors, colorsMapping, HIDDEN_TAGS, InteractedElements } from "./Globals";
import { PublicAPI } from "./Settings";
import store from "./Store/store";

export const equals = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
}

export const htmlDecode = (content) => {
    let e = document.createElement('div');
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export const consoleLogEnabled = () => {
    return store.getState().Config.showConsoleLogs;
}

export const experimentalFeaturesEnabled = () => {
    return store.getState().Config.showExperimental;
}

export const hideAllModals = () => {
    window.$('.modal').modal('hide');
}

export const showModal = (id) => {
    consoleLogEnabled() && console.log('Showing modal with id: ' + id);
    hideAllModals();
    window.$(`#${id}`).modal('show');
}

export const printElem = (elem) => {
    window.print();
}

export const getObjFromSimpleString = (string) => {
    return {
        value: string,
        label: string
    }
}

export const getPublicApiKey = () => {
    if (experimentalFeaturesEnabled()) {
        return PublicAPI.dummyApiKey
    } else {
        return PublicAPI.realApiKey
    }
}

export const saveTextToClipboard = (text, html = false) => {
    if (html) {
        document.addEventListener('copy', listener);
        const listener = (e) => {
            e.clipboardData.setData('text/html', text);
            e.clipboardData.setData('text/plain', text);
            e.preventDefault();
        }
        document.removeEventListener('copy', listener);
    }
    var dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    let res = document.execCommand('copy');
    document.body.removeChild(dummy);
    return !!res;
}

export const generate_token = (length) => {
    //edit the token allowed characters
    var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
    var b = [];
    for (var i = 0; i < length; i++) {
        var j = (Math.random() * (a.length - 1)).toFixed(0);
        b[i] = a[j];
    }
    return b.join("");
}

export const sanitizeInteracted = (interactedObj) => {

    const mappingNaming = (key, element) => {
        switch (key) {
            case InteractedElements.distance: return `${element} Km(s)`
            case InteractedElements.availability: return `${element.day.label} ${element.time.label}`
            default: return element
        }
    }

    let interactedArray = [];
    for (const key in interactedObj) {
        if (Object.hasOwnProperty.call(interactedObj, key)) {
            const element = interactedObj[key];
            if (element && ((element.label && element.label.length > 0) || element.length) && !HIDDEN_TAGS.includes(key)) {
                //Then the element was interacted, so we need to add to the general array
                let labelText = '';
                key.match(/([A-Z]?[^A-Z]*)/g).slice(0, -1).map((text) => labelText += `${labelText && ` `}${text.charAt(0).toUpperCase()}${text.substring(1, text.length)}`)
                if ((!element.label && !element.value) && typeof element === 'string') {
                    let tempString = {
                        type: "string",
                        id: element,
                        keyStore: key,
                        label: labelText,
                        value: mappingNaming(key, element),
                        color: colorsMapping.find(color => color.value.toLowerCase() === key.toLowerCase())?.color || Colors.tagDefaultColor,
                    }
                    interactedArray.push(tempString);
                } else if (element.label) {
                    let tempString = {
                        type: "string",
                        id: element.value,
                        keyStore: key,
                        label: labelText,
                        value: mappingNaming(key, element.label),
                        color: colorsMapping.find(color => color.value.toLowerCase() === key.toLowerCase())?.color || Colors.tagDefaultColor,
                    }
                    interactedArray.push(tempString);
                } else if (key === InteractedElements.availability) {
                    element.map(item => {
                        let tempString = {
                            type: "availability",
                            id: item.id,
                            keyStore: key,
                            label: labelText,
                            value: mappingNaming(key, item),
                            color: colorsMapping.find(color => color.value.toLowerCase() === key.toLowerCase())?.color || Colors.tagDefaultColor,
                        }
                        interactedArray.push(tempString);
                    })
                } else if (key === InteractedElements.participation) {
                    element.map(item => {
                        let tempString = {
                            type: "participation",
                            id: item.id,
                            keyStore: key,
                            label: labelText,
                            value: mappingNaming(key, item),
                            color: colorsMapping.find(color => color.value.toLowerCase() === key.toLowerCase())?.color || Colors.tagDefaultColor,
                        }
                        interactedArray.push(tempString);
                    })
                } else if (typeof element === 'object') {
                    //Here we have to save array elements
                    element.map(item => {
                        let tempString = {
                            type: "array",
                            id: item.value,
                            keyStore: key,
                            label: labelText,
                            value: mappingNaming(key, item.label),
                            color: colorsMapping.find(color => color.value.toLowerCase() === key.toLowerCase())?.color || Colors.tagDefaultColor,
                        }
                        interactedArray.push(tempString);
                    })
                } else {
                    //Something went wrong
                    console.error('Something went wrong');
                }
            } else {
                //Then the element is not interacted, so we have to try to remove from the array
                interactedArray = interactedArray.filter(item => item.label !== key);
            }
        }
    }
    return interactedArray;
}