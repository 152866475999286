import logoImg from './Assets/logo/waltham_forest_logo.png';

// let developmentValueSaved = sessionStorage.getItem('developerSettings');
// if (developmentValueSaved === null) {
//     console.log('Saving development status');
//     sessionStorage.setItem('developerSettings', process.env.NODE_ENV === 'development');
// }

export const isDevelopment = false// process.env.NODE_ENV === 'development';
export const showDeveloperSettings = isDevelopment;// JSON.parse(sessionStorage.getItem('developerSettings'))
//export const isDevelopment = false;

export const GlobalSettings = {
    apiURL: isDevelopment ? 'http://localjssfapi.vidavia.net' : 'https://londonsportapi.theservicefinder.com',
    apiKey: 'Another12345Code',
    geoHelperApi: 'https://geohelper.vidavia.net',
    geoHelperKey: 'Another12345Code',
    instanceKey: 'BAC1FF4EEDEE2',

    //Customization
    logoSettings: {
        logoImg,
        logoWidth: 250,
        logoHeight: 'auto',
        logoWidthMobile: 135,
        logoHeightMobile: 'auto',
    },
    pcWidthSize: 768,
    maxActivitiesInShareURL: 15,

    //General Settings
    showSettingsMenuByDefault: false,
    testing: isDevelopment,
    showConsoleLog: true,
    showExperimental: false,
}

export const PublicAPI = {
    dummyApiKey: 'jAyxqV1IVTlcPeQV2aujF05X0483cOKu',
    realApiKey: `FWL7l0z8gNqaiYx6wYj8DFH3qPu7yI5r`,
    baseURL: `https://search.imin.co/events-api/v2`,
    endpoints: {
        eventSeries: `event-series`
    },
    defaultOptions: {
        maxDistance: 40075, //Add more km are not possible because this is the circumference of the earth.
        distance: 40075, //If user doesn't select any distance, this will be used,
        latitude: '51.7520131',
        longitude: '-1.2578499'
    }
}