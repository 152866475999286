import { Paths } from "../../Routes";

//Pages
const areaSelector = [
    Paths.homePage.path
]
const aboutButton = [
    Paths.homePage.path,
    Paths.aboutPage.path
]

const logoutButton = [
    Paths.adminInfoPath.path,
]

export const checkPath = (pathStore) => {
    let showAreaSelector = checkAreaSelector(pathStore);
    let showAboutButton = checkAboutButton(pathStore);
    let showLogoutButton = checkLogoutButton(pathStore);

    return {
        showAreaSelector,
        showAboutButton,
        showLogoutButton,
    }
}

//Check for area selector
const checkAreaSelector = (pathStore) => {
    for (let i = 0; i < areaSelector.length; i++) {
        let pathToShow = areaSelector[i];
        if (pathStore === pathToShow) {
            return true;
        }
        return false;
    }
}

//Check for buttons
const checkAboutButton = (pathStore) => {
    for (let i = 0; i < aboutButton.length; i++) {
        let pathToShow = aboutButton[i];
        if (pathStore === pathToShow) {
            return true;
        }
        return false;
    }
}

//Check for Logout button
const checkLogoutButton = (pathStore) => {
    for (let i = 0; i < logoutButton.length; i++) {
        let pathToShow = logoutButton[i];
        if (pathStore === pathToShow) {
            return true;
        }
        return false;
    }
}