import { GlobalSettings } from "../../../Settings";
import { SET_API, SET_API_QUERY, SET_HOME, SET_PATH, TOGGLE_CONSOLE_LOG, TOGGLE_EXPERIMENTAL } from "./Types";

const init_data = {
    path: '',
    showConsoleLogs: GlobalSettings.showConsoleLog,
    showExperimental: GlobalSettings.showExperimental,
    fromHome: '',
}

const Config = function (state = init_data, { type, payload }) {
    switch (type) {
        case SET_PATH:
            return {
                ...state,
                path: payload
            }
        case SET_API_QUERY:
            return {
                ...state,
                apiQuery: payload
            }
        case SET_HOME:
            return {
                ...state,
                fromHome: true
            }
        case SET_API:
            return {
                ...state,
                api: payload
            }
        case TOGGLE_CONSOLE_LOG:
            return {
                ...state,
                showConsoleLogs: !state.showConsoleLogs
            }
        case TOGGLE_EXPERIMENTAL:
            return {
                ...state,
                showExperimental: !state.showExperimental
            }
        default: return state;
    }
}

export default Config;