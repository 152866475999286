import { SET_SEARCH_RESULT } from "./Types";

const init_data = {}

const SearchResults = function (state = init_data, { type, payload }) {
    switch (type) {
        case SET_SEARCH_RESULT:
            return payload;
        default: return state;
    }
}

export default SearchResults;