import React from 'react';

const getRoute = (path, component, exact = true) => ({
    path: path.path,
    component,
    exact
})

export const Paths = {
    homePage: {
        path: '/',
        title: 'Home'
    },
    contactUsPage: {
        path: '/contact',
        title: 'Contact Us',
    },
    aboutPage: {
        path: '/about',
        title: 'About',
    },
    activityPage: {
        path: '/activity/:id',
        title: 'Activity Info',
    },
    activityList: {
        path: '/activity-list/:listId',
        title: 'Activity List'
    },
    adminPath: {
        path: '/admin',
        title: 'Admin',
    },
    adminInfoPath: {
        path: '/admin/info',
        title: 'Admin Info'
    },
    newAreaPath: {
        path: '/admin/new-area',
        title: 'New Area',
    },
    editAreaPath: {
        path: '/admin/edit-area/:id',
        title: 'Edit Area',
    },
    widgetMakerPath: {
        path: '/widget/maker/:organisationId',
        title: 'Widget Make'
    },
    widgetPagePath: {
        path: '/widget/:organisationId',
        title: 'Widget Info',
    },
    page404: {
        path: "*",
        title: 'Page Not Found'
    }
}

//Components
const homeComponent = React.lazy(() => import('./Views/HomePage/HomePage'));
const aboutComponent = React.lazy(() => import('./Views/About/AboutPage'));
const contactUsComponent = React.lazy(() => import('./Views/ContactUs/ContactUs'));
const adminComponent = React.lazy(() => import('./Views/Admin/AdminPage'));
const adminInfoComponent = React.lazy(() => import('./Views/Admin/AdminInfo/AdminInfo'));
const page404Component = React.lazy(() => import('./Views/404/Page404'));
const activityPageComponent = React.lazy(() => import('./Views/ActivityPage/ActivityPage'));
const activityListComponent = React.lazy(() => import('./Views/ActivityList/ActivityList'));
const newAreaComponent = React.lazy(() => import('./Views/Admin/NewArea/NewArea'));
const editAreaComponent = React.lazy(() => import('./Views/Admin/NewArea/NewArea'));

//Routes
const HomeRoute = getRoute(Paths.homePage, homeComponent)
const AboutRoute = getRoute(Paths.aboutPage, aboutComponent)
const ContactUsRoute = getRoute(Paths.contactUsPage, contactUsComponent);
const AdminRoute = getRoute(Paths.adminPath, adminComponent)
const AdminInfoRoute = getRoute(Paths.adminInfoPath, adminInfoComponent);
const ActivityPageRoute = getRoute(Paths.activityPage, activityPageComponent);
const ActivityListRoute = getRoute(Paths.activityList, activityListComponent);
const NewAreaRoute = getRoute(Paths.newAreaPath, newAreaComponent);
const EditAreaRoute = getRoute(Paths.editAreaPath, editAreaComponent);

export const Page404Route = getRoute(Paths.page404, page404Component, false);

export const Routes = [
    // Page404Route,
    HomeRoute,
    ContactUsRoute,
    AboutRoute,
    AdminRoute,
    ActivityPageRoute,
    AdminInfoRoute,
    ActivityListRoute,
    NewAreaRoute,
    EditAreaRoute,
]