import { InteractedElements } from "../../../Globals";
import { ADD_ACTIVITY_CONTEXT, ADD_ACTIVITY_TYPE, CLEAR_INTERACTED, SET_ACCESSIBILITY, SET_ACTIVITY_TYPE, SET_AGE, SET_ASSURANCE, SET_AVAILABILITY, SET_DISTANCE, SET_FACILITIES, SET_GENDER, SET_INTENSITY, SET_INTERACTED, SET_LATITUDE, SET_LONGITUDE, SET_PARTICIPATION, SET_POSTCODE, SET_SUPPORT } from "./Types";

const init_data = {
    [InteractedElements.postcode]: {},
    [InteractedElements.latitude]: "",
    [InteractedElements.longitude]: "",
    [InteractedElements.distance]: {},
    [InteractedElements.age]: {},
    [InteractedElements.gender]: {},
    [InteractedElements.accessibility]: {},
    [InteractedElements.availability]: [],
    [InteractedElements.activityContext]: [],
    [InteractedElements.activityTypes]: [],
    [InteractedElements.intensity]: {},
    [InteractedElements.facilities]: {},
    [InteractedElements.participation]: {},
    [InteractedElements.support]: {},
    [InteractedElements.assurance]: {},
}

const InteractedReducer = function (state = init_data, { type, payload }) {
    switch (type) {
        case SET_AGE:
            return {
                ...state,
                [InteractedElements.age]: payload
            };
        case SET_GENDER:
            return {
                ...state,
                [InteractedElements.gender]: payload
            };
        case SET_DISTANCE:
            return {
                ...state,
                [InteractedElements.distance]: payload
            };
        case SET_POSTCODE:
            return {
                ...state,
                [InteractedElements.postcode]: payload
            };
        case SET_ACCESSIBILITY:
            return {
                ...state,
                [InteractedElements.accessibility]: payload
            }
        case SET_AVAILABILITY:
            return {
                ...state,
                [InteractedElements.availability]: [...payload]
            };
        case ADD_ACTIVITY_CONTEXT:
            return {
                ...state,
                [InteractedElements.activityContext]: [...payload]
            }
        case ADD_ACTIVITY_TYPE:
            return {
                ...state,
                [InteractedElements.activityTypes]: [...state[InteractedElements.activityTypes], payload]
            }
        case SET_ACTIVITY_TYPE:
            return {
                ...state,
                [InteractedElements.activityTypes]: [...payload]
            }
        case SET_LATITUDE:
            return {
                ...state,
                [InteractedElements.latitude]: payload
            }
        case SET_LONGITUDE:
            return {
                ...state,
                [InteractedElements.longitude]: payload
            }
        case SET_INTENSITY:
            return {
                ...state,
                [InteractedElements.intensity]: payload
            }
        case SET_FACILITIES:
            return {
                ...state,
                [InteractedElements.facilities]: payload
            }
        case SET_PARTICIPATION:
            return {
                ...state,
                [InteractedElements.participation]: payload
            }
        case SET_SUPPORT:
            return {
                ...state,
                [InteractedElements.support]: payload
            }
        case SET_ASSURANCE:
            return {
                ...state,
                [InteractedElements.assurance]: payload
            }
        case SET_INTERACTED:
            return { ...payload }
        case CLEAR_INTERACTED:
            return { ...init_data };
        default: return state;
    }
}

export default InteractedReducer;