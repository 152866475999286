import { default as axios, default as Axios } from "axios";
import { consoleLogEnabled, getPublicApiKey } from "../../functions";
import { InteractedElements } from "../../Globals";
import { GlobalSettings, PublicAPI } from "../../Settings";
import { getSetActivityIndividualListAction, getSetActivityTeamListAction } from "../../Store/reducers/ActivitiesTypes/Actions";
import { getSetActivityListAction } from "../../Store/reducers/ActivityList/Actions";
import { getSetApiURLAction } from "../../Store/reducers/Config/Actions";
import { getSetLatitudeAction, getSetLongitudeAction } from "../../Store/reducers/Interacted/Actions";
import { getSetLanguagesAction } from "../../Store/reducers/Languages/Actions";
import { getSetSearchResultsAction } from "../../Store/reducers/SearchResults/Actions";
import store from "../../Store/store";
import { getApiInfoData, getLanguagesData, getPostcodeData } from './actions';

export const getURLWithParams = (page, perPage) => {
    const {
        [InteractedElements.latitude]: latitude,
        [InteractedElements.longitude]: longitude,
        [InteractedElements.distance]: distance,
        [InteractedElements.age]: age,
        [InteractedElements.gender]: gender,
        [InteractedElements.accessibility]: accessibility,
        [InteractedElements.activityTypes]: activityTypes,
        [InteractedElements.availability]: availability,
        [InteractedElements.intensity]: intensity,
        [InteractedElements.facilities]: facilities,
        [InteractedElements.support]: support,
        [InteractedElements.participation]: participation,
        [InteractedElements.assurance]: assurance
    } = { ...store.getState().Interacted };

    let startURL = `${PublicAPI.baseURL}/event-series?`; //Base url
    let finalURL = ``;

    //Add distance (We will use geo as first element because is required)
    finalURL = `${finalURL}geo[radial]=${latitude || PublicAPI.defaultOptions.latitude},${longitude || PublicAPI.defaultOptions.longitude},${distance.value || PublicAPI.defaultOptions.distance}`;

    //Add age
    if (age.value) finalURL = `${finalURL}&ageRangeMin[gte]=${age.value}&ageRangeMax[lte]=${age.value}`

    //Add gender
    if (gender.value) finalURL = `${finalURL}&genderRestriction=${gender.value}`;

    //Add accessibility (This could be an array, but not implemented at the moment)
    if (accessibility.value) finalURL = `${finalURL}&accessibilitySupportId=${encodeURIComponent(accessibility.value)}`;

    //Availability
    if (availability.length) {
        availability.map(item => {
            finalURL = `${finalURL}&dayOfWeek=${encodeURIComponent(item.day.value)}&startTime[gte]=${encodeURIComponent(item.time.startTime)}`
        })
    }

    //Participation Value
    if (participation.value) {
        finalURL = `${finalURL}&activityConceptCollectionId=${encodeURIComponent(participation.value)}`;
    }

    //Activity Types & Activity Context
    if (activityTypes.length > 0) {
        activityTypes.map(act => {
            finalURL = `${finalURL}&activityId=${encodeURIComponent(act.value)}`
        })
    }

    //Intensity, Facilities, Participation and Support 
    if (intensity.value) finalURL = `${finalURL}&iminTag=${intensity.value}`
    if (facilities.value) finalURL = `${finalURL}&iminTag=${facilities.value}`
    if (support.value) finalURL = `${finalURL}&iminTag=${support.value}`
    if (assurance.value) finalURL = `${finalURL}&iminTag=${assurance.value}`

    finalURL = `${finalURL}&page=${page}&limit=${perPage}` //Add pagination stuff
    finalURL = `${finalURL}&mode=upcoming-sessions` //Add mode
    consoleLogEnabled() && console.log(finalURL);
    let encodedURL = `${startURL}${finalURL}`
    consoleLogEnabled() && console.log(encodedURL);
    return encodedURL;
}

export const getAPIInfo = async () => {
    return await Axios.post(`${GlobalSettings.apiURL}/api/getAPI.php`, getApiInfoData())
        .then(({ data }) => {
            consoleLogEnabled() && console.log(data);
            if (data.status) {
                store.dispatch(getSetApiURLAction(data.api));
            }
            return data.status;
        })
        .catch(e => {
            consoleLogEnabled() && console.log(e);
            return false;
        })
}

export const getLanguages = async () => {
    return await Axios.post(`${GlobalSettings.apiURL}/languages/getAll.php`, getLanguagesData())
        .then(({ data }) => {
            consoleLogEnabled() && console.log(data);
            if (data.status) {
                store.dispatch(getSetLanguagesAction(data.languages));
            }
            return data.status;
        })
        .catch(e => {
            consoleLogEnabled() && console.log(e);
            return false;
        })
}

export const getResults = async (page, perPage) => {
    return await axios.get(getURLWithParams(page, perPage), {
        headers: {
            'X-API-KEY': getPublicApiKey()
        }
    })
        .then(async (res) => {
            consoleLogEnabled() && console.log('Response from API: ', res);
            if (res.status === 200) {
                consoleLogEnabled() && console.log('Results from API: ', res.data);
                let dataCopy = { ...res.data };
                if (!dataCopy['imin:item']) {
                    dataCopy['imin:item'] = []
                }
                let items = {
                    items: dataCopy['imin:item'],
                    view: dataCopy.view,
                };
                console.log(items);

                await store.dispatch(getSetSearchResultsAction(items));
                res.data = { ...dataCopy };
                return res
            } else {
                return res.status
            }
        })
        .catch(e => {
            console.trace(e);
            return false;
        })
}

export const getActivityList = async () => {
    consoleLogEnabled() && console.log('Loading extra info for activities...')
    await axios.get(`https://openactive.io/activity-list/activity-list.jsonld`)
        .then(({ data }) => {
            if (data) {
                consoleLogEnabled() && console.log('Extra activities info fetched!');
                store.dispatch(getSetActivityListAction(data.concept));
            } else {
                consoleLogEnabled() && console.log('Extra info couldn\'t fetched!');
            }
        })
        .catch(e => {
            console.trace(e);
        })
}

export const getTeamActivities = async () => {
    consoleLogEnabled() && console.log('Loading extra info for activities...')
    await axios.get(`${window.location.protocol}//${window.location.host}/json/conceptCollections-team.jsonld`)
        .then(({ data }) => {
            if (data) {
                consoleLogEnabled() && console.log('Extra activities info fetched!');
                store.dispatch(getSetActivityTeamListAction(data.member));
            } else {
                consoleLogEnabled() && console.log('Extra info couldn\'t fetched!');
            }
        })
        .catch(e => {
            console.trace(e);
        })
}

export const getIndividualActivities = async () => {
    consoleLogEnabled() && console.log('Loading extra info for activities...')
    await axios.get(`${window.location.protocol}//${window.location.host}/json/conceptCollections-individual.jsonld`)
        .then(({ data }) => {
            if (data) {
                consoleLogEnabled() && console.log('Extra activities info fetched!');
                store.dispatch(getSetActivityIndividualListAction(data.member));
            } else {
                consoleLogEnabled() && console.log('Extra info couldn\'t fetched!');
            }
        })
        .catch(e => {
            console.trace(e);
        })
}

export const getAccessibilityList = async () => {
    await consoleLogEnabled() && console.log('Loading extra info for accessibility...')
    return axios.get(`https://openactive.io/accessibility-support/accessibility-support.jsonld`)
        .then(({ data }) => {
            if (data) {
                consoleLogEnabled() && console.log('Extra accessibility info fetched!');
                //store.dispatch(getSetAccessibilityListAction(data.concept));
                return data.concept;
            } else {
                consoleLogEnabled() && console.log('Extra info couldn\'t fetched!');
            }
        })
        .catch(e => {
            console.trace(e);
        })
}

export const getGenderList = async () => {
    await consoleLogEnabled() && console.log('Loading extra info for gender...')
    return axios.get(`https://meta.imin.co/v2.json#/imin:index/Enumeration/imin:index/`)
        .then(({ data }) => {
            if (data) {
                consoleLogEnabled() && console.log('Extra gender info fetched!');
                //store.dispatch(getSetAccessibilityListAction(data.concept));
                return data['imin:index'].Enumeration['imin:index'].GenderRestrictionType['imin:item'];
            } else {
                consoleLogEnabled() && console.log('Extra info couldn\'t fetched!');
            }
        })
        .catch(e => {
            console.trace(e);
        })
}
export const getAvailabilityDayList = async () => {
    await consoleLogEnabled() && console.log('Loading extra info for availability...')
    return axios.get(`https://meta.imin.co/v2.json#/imin:index/Enumeration/imin:index/`)
        .then(({ data }) => {
            if (data) {
                consoleLogEnabled() && console.log('Extra gender info fetched!');
                //store.dispatch(getSetAccessibilityListAction(data.concept));
                return data['imin:index'].Enumeration['imin:index'].DayOfWeek['imin:item'];
            } else {
                consoleLogEnabled() && console.log('Extra info couldn\'t fetched!');
            }
        })
        .catch(e => {
            console.trace(e);
        })
}

export const getLatLong = async (postcode) => {
    return await axios.post(`${GlobalSettings.geoHelperApi}/postcode/getPostCode.php`, getPostcodeData(postcode))
        .then(({ data }) => {
            consoleLogEnabled() && console.log(data);
            if (data.status) {
                store.dispatch(getSetLatitudeAction(data.latitude));
                store.dispatch(getSetLongitudeAction(data.longitude));
                return data.status
            } else {
                return false
            }
        })
        .catch(e => {
            console.trace(e)
            return false;
        })
}