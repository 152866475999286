import { GlobalSettings } from "../../Settings";

export const getAllAreasData = () => ({
    apiKey: GlobalSettings.apiKey,
    instanceKey: GlobalSettings.instanceKey
})


export const getAreasData = (disabled) => ({
    apiKey: GlobalSettings.apiKey,
    instancekey: GlobalSettings.instanceKey,
    includeDisabledAreas: disabled
})