import axios from "axios";
import { consoleLogEnabled } from "../../functions";
import { GlobalSettings } from "../../Settings";
import { getSetAreasAction } from "../../Store/reducers/Areas/Actions";
import { getSetSelectedAreaAction } from "../../Store/reducers/SelectedArea/Actions";
import store from "../../Store/store";
import { getAreasData } from "./actions";

export const getAllAreas = async (fetchDisabled = null) => {
    return await axios.post(`${GlobalSettings.apiURL}/area/getAreas.php`, getAreasData(fetchDisabled))
        .then(({ data }) => {
            consoleLogEnabled() && console.log(data);
            if (data.status) {
                store.dispatch(getSetAreasAction(data.areas));
                let areaSelected = data.areas.find(area => area.selected === 1);
                if (areaSelected) {
                    store.dispatch(getSetSelectedAreaAction(areaSelected));
                } else {
                    return false;
                }
                return data.status
            } else {
                return false
            }
        })
        .catch(e => {
            console.log(e)
            return false;
        })
}