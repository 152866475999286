import { default as axios, default as Axios } from "axios";
import { consoleLogEnabled } from "../../functions";
import { GlobalSettings } from "../../Settings";
import { getSetAreasAction } from "../../Store/reducers/Areas/Actions";
import { getSetInstanceAction } from "../../Store/reducers/InstanceOpened/Actions";
import { getSetIntensityAction } from "../../Store/reducers/Interacted/Actions";
import { getSetUserAction } from "../../Store/reducers/User/Actions";
import store from "../../Store/store";
import { deleteAreaData, getNewAreaData, getUserAuthData, getUserData, toggleAreaData } from "./actions";

export const login = async (email, password) => {
    return await Axios.post(`${GlobalSettings.apiURL}/user/login.php`, getUserAuthData(email, password))
        .then(({ data }) => {
            consoleLogEnabled() && console.log(data);
            if (data.status) {
                console.log(data);
                let newUser = { ...data.user };
                store.dispatch(getSetUserAction(newUser))
                sessionStorage.setItem('userInfo', btoa(btoa(newUser.id)))

                //Save selected instance
                const { instance } = newUser.instances.find(instance => instance.instance.instance_key === GlobalSettings.instanceKey);
                store.dispatch(getSetInstanceAction(instance));
                return data.status;
            }
            return data.status;
        })
        .catch(e => {
            consoleLogEnabled() && console.log(e);
            return false;
        })
}

export const createUser = async (email, password) => {
    return await axios.post(`${GlobalSettings.apiURL}/user/signup.php`, getUserAuthData(email, password))
        .then(({ data }) => {
            return data;
        })
        .catch(e => {
            console.trace(e);
            return false;
        })
}

export const getUser = async (id) => {
    return await axios.post(`${GlobalSettings.apiURL}/user/info.php`, getUserData(id))
        .then(({ data }) => {
            console.log(data);
            let newUser = { ...data.user };
            store.dispatch(getSetUserAction(newUser))

            //Save selected instance
            const { instance } = newUser.instances.find(instance => instance.instance.instance_key === GlobalSettings.instanceKey);
            console.log(instance);
            store.dispatch(getSetInstanceAction(instance));
            store.dispatch(getSetAreasAction(instance.areas))
            return data.user;
        })
        .catch(e => {
            console.trace(e);
            return false;
        })
}

export const saveArea = async (area, userid, instanceid) => {
    return await axios.post(`${GlobalSettings.apiURL}/area/createArea.php`, getNewAreaData(area, userid, instanceid))
        .then(({ data }) => {
            if (data.status) {
                store.dispatch(getSetIntensityAction(data.instance));
                store.dispatch(getSetAreasAction([...data.instance.areas]));
                return data;
            }
            return data.status
        })
        .catch(e => {
            console.trace(e);
            return false;
        })
}

export const toggleArea = async (areaid) => {
    return await axios.post(`${GlobalSettings.apiURL}/area/toggleArea.php`, toggleAreaData(areaid))
        .then(({ data }) => {
            console.log(data);
            if (data.status) {
                store.dispatch(getSetAreasAction([...data.areas]));
                return data;
            }
            return data.status
        })
        .catch(e => {
            console.trace(e);
            return false;
        })
}

export const deleteArea = async (areaid) => {
    return await axios.post(`${GlobalSettings.apiURL}/area/deleteArea.php`, deleteAreaData(areaid))
        .then(({ data }) => {
            if (data.status) {
                store.dispatch(getSetAreasAction([...data.areas]));
                return data;
            }
            return data.status
        })
        .catch(e => {
            console.trace(e);
            return false;
        })
}

export const toggleDefaultArea = async (areaid) => {
    return await axios.post(`${GlobalSettings.apiURL}/area/toggleDefaultArea.php`, toggleAreaData(areaid))
        .then(({ data }) => {
            if (data.status) {
                store.dispatch(getSetAreasAction([...data.areas]));
                return data;
            }
            return data.status
        })
        .catch(e => {
            console.trace(e);
            return false;
        })
}