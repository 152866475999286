import { ADD_ACTIVITY_TO_FAV, REMOVE_ACTIVITY_FROM_FAV } from "./Types";

const init_data = []

const FavActivities = function (state = init_data, { type, payload }) {
    switch (type) {
        case ADD_ACTIVITY_TO_FAV:
            return [...state, payload];
        case REMOVE_ACTIVITY_FROM_FAV:
            return [...state.filter(fav => fav.id !== payload.id)]
        default: return state;
    }
}

export default FavActivities;