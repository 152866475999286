import { REMOVE_INSTANCE, SET_INSTANCE } from "./Types";

export const getSetInstanceAction = (payload) => ({
    type: SET_INSTANCE,
    payload,
})

export const getRemoveInstanceAction = (payload) => ({
    type: REMOVE_INSTANCE,
    payload
})
