import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Footer from './Components/Shared/Footer/Footer';
import Header from './Components/Shared/Header/Header';
import SideSettings from './Components/Shared/SideSettings/SideSettings';
import { getActivityList, getIndividualActivities, getTeamActivities } from './Functions/App/functions';
import { Page404Route, Routes } from './Routes';
import { showDeveloperSettings } from './Settings';

const App = () => {
  const [loaded, setLoaded] = useState(false);
  const [apiError, setApiError] = useState(false);

  const [tabWidth, setTabWidth] = useState(window.innerWidth);

  //ComponentDidMount
  useEffect(() => {

    window.addEventListener('resize', handleWidth);

    return () => {
      window.removeEventListener('resize', handleWidth);
    }
  }, [])

  const handleWidth = (e) => {
    const { innerWidth } = e.target;
    setTabWidth(innerWidth);
  }

  //ComponentDidMount
  useEffect(() => {
    getAPIData();
  }, [])

  const getAPIData = async () => {
    await getActivityList(); //Get all activity list for mapping data
    await getTeamActivities();
    await getIndividualActivities();
    //let apiLoaded = await getAPIInfo(); //Get all API info
    //let languageLoaded = await getLanguages(); //Get all languages info
    //let circumstancesLoaded = await getCircumstances(); //Get all circumstances info
    setLoaded(true)

    //Check if the API data is getted correctly
    setApiError(false);

    //Check if the app get the languages correctly

    //Check if the app get the circumstances correctly

  }

  return (
    <div className="overflow-hidden">
      <div className="noPrint">
        {showDeveloperSettings && < SideSettings />}
      </div>
      {loaded ?
        apiError ?
          <div className="d-flex justify-content-center align-items-center h-100 w-100 p-5">
            API Error. Data not available
        </div>
          :
          <div className="overflow-auto d-flex flex-column min-vh-100">

            <Header tabWidth={tabWidth} />
            <Switch>
              {
                Routes.map((route, index) => (
                  <Route {...route} key={index} />
                ))
              }
              <Route {...Page404Route} />
            </Switch>
            <Footer tabWidth={tabWidth} />
          </div>
        :
        <div className="loader">
          <div className="bar1"></div>
        </div>
      }
    </div>
  );
}

export default App;