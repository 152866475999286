import { SET_ACTIVITY_INDIVIDUAL_TEST, SET_ACTIVITY_TEAM_LIST } from "./Types";

export const getSetActivityTeamListAction = (payload) => ({
    type: SET_ACTIVITY_TEAM_LIST,
    payload,
})

export const getSetActivityIndividualListAction = (payload) => ({
    type: SET_ACTIVITY_INDIVIDUAL_TEST,
    payload,
})
