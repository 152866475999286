import { REMOVE_INSTANCE, SET_INSTANCE } from "./Types";

const init_data = {}

const Instance = function (state = init_data, { type, payload }) {
    switch (type) {
        case SET_INSTANCE:
            return { ...payload }
        case REMOVE_INSTANCE:
            return {}
        default: return state;
    }
}

export default Instance;